import { Floor } from 'pages/Projects/data'
import { EditorState } from './types'
import { EditorError } from 'pages/Projects/types'

export const INIT_EDITOR = "INIT_EDITOR"
export const CHANGE_SELECTED_AR_CONTENT = "CHANGE_SELECTED_AR_CONTENT"
export const CHANGE_SELECTED_POI = "CHANGE_SELECTED_POI"
export const TOGGLE_MAP_OVERLAY = "TOGGLE_MAP_OVERLAY"
export const TOGGLE_MAP_OVERLAY_AVAILABLE = "TOGGLE_MAP_OVERLAY_AVAILABLE"
export const CHANGE_FLOOR = "CHANGE_FLOOR"
export const INIT_FLOORS = "INIT_FLOORS"
export const UPDATE_ERROR = "UPDATE_ERROR"
export const UPDATE_INITIAL_POSITION = "UPDATE_INITIAL_POSITION"

export const initEditor = (editor: EditorState) => ({
    type: INIT_EDITOR,
    payload: editor,
})

export const changeSelectedArContent = (arContentId: string) => ({
    type: CHANGE_SELECTED_AR_CONTENT,
    payload: arContentId,
})

export const changeSelectedPoi = (poiId: string) => ({
    type: CHANGE_SELECTED_POI,
    payload: poiId,
})

export const changeSelectedFloor = (floor?: Floor) => ({
    type: CHANGE_FLOOR,
    payload: floor,
})

export const initFloors = (floors: Floor[]) => ({
    type: INIT_FLOORS,
    payload: floors,
})

export const updateError = (errors: EditorError[]) => ({
    type: UPDATE_ERROR,
    payload: errors,
})

export const updateInitialPosition = (position: THREE.Vector3) => ({
    type: UPDATE_INITIAL_POSITION,
    payload: position,
})

export const toggleMapOverlay = (isOpen: boolean) => ({
    type: TOGGLE_MAP_OVERLAY,
    payload: isOpen,
})

export const toggleMapOverlayAvailable = (isAvailable: boolean) => ({
    type: TOGGLE_MAP_OVERLAY_AVAILABLE,
    payload: isAvailable,
})
