import React, { useRef, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as THREE from "three";
import { nanoid } from 'nanoid';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TransformControls } from './components/TransformControls/TransformControls';
import { ControlMode } from "../common/TransformControl/TransformControl"

import { ARContent, Project, usePointCloudsData, PointCloudPlace, Poi } from "../../data";

// Mapbox CSS
import 'mapbox-gl/dist/mapbox-gl.css';
import JSZip from 'jszip';
import { Spinner } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import PinIcon from '../../../assets/images/pin-min.png';
import { EditorError, EditorErrorType } from '../../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import { changeSelectedArContent, updateError } from 'store/editor/actions'
import { ApplicationState } from 'store/data';
import { EditorState } from 'store/editor/types'
import { imageScale } from 'constants/const';
import { checkStatus, filterBlacklistedObject, getExtension, getFileUrl, getModelTransform, getRaycasterParams, getSceneTransform, pointcloudLoader } from './helper';
import { Marker } from './components/Marker/Marker'
import { createRoot } from 'react-dom/client';
import { } from 'react-dom/server'
import { TipVariant, UsageTip } from '../common/UsageTip/UsageTip';

// ##### Transform Control is currently too glitchy to be part of Prod, will be fixed in later date #####
// ##### Update 5/9/2023 Most of the issues are fixed, switch will be removed after test on prod #####
const EnableTransformControl = true;

let pointclouds: PointCloudPlace[] = [
    {
        name: "onesiam-skywalk",
        originCoordinate: {
            displayOriginCoordinates: [100.530648, 13.746320, 5],
            displayRotation: { w: 0.016, x: 0.027, y: -0.061, z: 0.998 },
            multiplyScalar: 10,
        },
        publicPointCloudFile: "https://graffity-sdk-public.s3.ap-southeast-1.amazonaws.com/point-clouds/onesiam-skywalk.ply",
        _id: '',
        category: 0,
        type: '',
        publicSplatFile: '',
        location: {
            type: "Polygon",
            coordinates: [],
        },
    },
    // {
    //     name: "kaitak-tower",
    //     originLngLat: [100.549, 13.9095],
    //     altitude: 10,
    //     positionXYZ: [32, -10, -64],
    //     rotationXYZ: [1.35, Math.PI / 2 - 0.68, Math.PI / 2 + 0.06],
    //     multiplyScalar: 24,
    //     url: "https://graffity-sdk-public.s3.ap-southeast-1.amazonaws.com/point-clouds/kaitak.ply"
    // },
]

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

interface StateProps {
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

interface OwnProps {
    project: Project
    arContents: ARContent[]
    onArContentUpdate: (index: number, obj: ARContent) => void
    onArContentStateUpdate: (index: number, object: ARContent) => void
    controlMode: ControlMode
}

type WorldAnchorEditorProps = StateProps & OwnProps & DispatchProps

const WorldAnchorEditorComponent = ({ project, arContents, onArContentUpdate, onArContentStateUpdate, editorState, dispatch, controlMode }: WorldAnchorEditorProps) => {
    const [pointClouds, isPointCloudFetching] = usePointCloudsData(project.organizationId);

    const [errContentList, setErrContentList] = useState<Set<String>>(new Set<string>())
    const errContentListRef = useRef<Set<String>>(new Set<string>())
    const controlModeRef = useRef(controlMode)
    const selectedContentRef = useRef('')

    const [urlParams] = useSearchParams();
    const [mapCenter] = useState(urlParams.get('center'));

    const mapContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map>();

    const [lng, setLng] = useState(
        mapCenter ? parseFloat(mapCenter!.split(",")[0]) : project.previewLocations[0].location2D.coordinates[0]
    );
    const [lat, setLat] = useState(
        mapCenter ? parseFloat(mapCenter!.split(",")[1]) : project.previewLocations[0].location2D.coordinates[1]
    );
    const [zoom, setZoom] = useState(18);
    const [isLoading, setIsLoading] = useState(false);

    const arContentsRef = useRef(arContents)
    useEffect(() => {
        arContentsRef.current = arContents
    }, [arContents])

    useEffect(() => {
        selectedContentRef.current = editorState.selectedObjectId
    }, [editorState.selectedObjectId])

    useEffect(() => {
        controlModeRef.current = controlMode
    }, [controlMode])

    const onObjectClick = (arContentId: string) => {
        dispatch(changeSelectedArContent(arContentId))
    }

    const initMap = () => {
        const attachMap = (setMap: React.Dispatch<React.SetStateAction<any>>, mapContainer: React.RefObject<HTMLDivElement>) => {
            const mapInit = new mapboxgl.Map({
                container: mapContainer.current || '', // NO ERROR
                center: [lng, lat],
                zoom: zoom,
                pitch: 60, // VIEW ORIENTATION
                customAttribution: "© Graffity Technologies Co., Ltd.",
            })
            setMap(mapInit);
        }

        !map && attachMap(setMap, mapContainer)
    }

    const onObjectError = (arContentId: string, message: string) => {
        if (errContentList.has(arContentId) || editorState.errors.find(error => error.objectid === arContentId)) return
        const newError: EditorError = {
            type: EditorErrorType.ArContentError,
            objectid: arContentId,
            message,
        }
        const newErrorList = new Set(errContentList).add(arContentId)
        setErrContentList(newErrorList)
        errContentListRef.current = newErrorList
        const newErrorsState = [
            ...editorState.errors,
            newError,
        ]
        dispatch(updateError(newErrorsState))
    }

    const mapMoveHandler = () => {
        if (!map) return; // wait for map to initialize
        map.on('move', () => {
            setLng(+map.getCenter().lng.toFixed(4));
            setLat(+map.getCenter().lat.toFixed(4));
            setZoom(+map.getZoom().toFixed(2));
        });
    }

    const handleArContentUpdate = (object: THREE.Object3D, originMercator: THREE.Vector3, mercatorMeterScale: number, shouldUpdateServer: boolean) => {
        const arContentId = object.name
        const arContentsFromRef = arContentsRef.current || []
        const arContentIndex = arContentsFromRef.findIndex(arContent => arContent._id === arContentId)
        if (arContentIndex === -1) return
        const updatedArContent: ARContent = { ...arContentsFromRef[arContentIndex] }

        const modelAsMercatorCoordinate = new mapboxgl.MercatorCoordinate(
            (object.position.x * mercatorMeterScale) + originMercator.x,
            (object.position.y * mercatorMeterScale) + originMercator.y,
            -object.position.z
        )
        const modelLatLng = modelAsMercatorCoordinate.toLngLat()
        updatedArContent.rotation = {
            x: Math.round(100 * (object.rotation.x + (Math.PI / 2)) * 180 / Math.PI) / 100,
            y: Math.round(100 * object.rotation.y * 180 / Math.PI) / 100,
            z: Math.round(100 * object.rotation.z * 180 / Math.PI) / 100,
        }
        updatedArContent.scale = {
            x: Math.round(100 * object.scale.x) / 100,
            y: Math.round(100 * object.scale.y) / 100,
            z: Math.round(100 * object.scale.z) / 100,
        }
        updatedArContent.location2D = {
            ...updatedArContent.location2D,
            coordinates: [modelLatLng.lng, modelLatLng.lat],
        }
        updatedArContent.altitude = -object.position.z

        if (shouldUpdateServer) {
            onArContentUpdate(arContentIndex, updatedArContent)
            return
        }
        onArContentStateUpdate(arContentIndex, updatedArContent)
    }

    const addZipModelToScene = async (object: ARContent, scene: THREE.Scene) => {

        const response = await fetch(object.downloadUrl);
        checkStatus(response);
        const arrayBuffer = await response.arrayBuffer();

        const result = await JSZip.loadAsync(arrayBuffer);

        const files = Object.values(result.files).filter(item => !item.dir);
        const entryFile = files.find(f => getExtension(f.name) === 'gltf');
        // Create blobs for every file resource
        let blobUrls: { [key: string]: string; } = {};
        for (const file of files) {
            setIsLoading(true);
            console.log(`Loading ${file.name}...`);
            blobUrls[file.name] = await getFileUrl(file);
        }

        const fileUrl = blobUrls[entryFile!.name];

        const light = new THREE.DirectionalLight(0xffffff, 1);
        scene.add(light);
        light.position.set(1.7, 1, -1);

        const loadingManager = new THREE.LoadingManager();
        loadingManager.setURLModifier((url) => {
            const parsedUrl = new URL(url);
            const origin = parsedUrl.origin;
            const path = parsedUrl.pathname;
            const relativeUrl = path.replace(origin + '/', "");

            if (blobUrls[relativeUrl] !== undefined) {
                return blobUrls[relativeUrl];
            }

            return url
        });
        const gltfLoader = new GLTFLoader(loadingManager);
        gltfLoader.load(
            fileUrl,
            (gltf) => {
                scene.add(gltf.scene);
                console.log("Added zip model to scene!");
                setIsLoading(false);
            },
            () => { },
            (e) => {
                console.error(e)
                setIsLoading(false)
                onObjectError(object._id || "", "Error loading content. Please try again or reupload the content")
            },
        );
    }

    const add3dModelToScene = (scene: THREE.Scene, object: ARContent, loaderGLTF: GLTFLoader) => {
        if (object.isIndoorPointCloud) {
            return undefined
        }
        if (errContentListRef.current.has(object._id || "")) return
        if (object.fileExtWithoutDot === "zip") {
            addZipModelToScene(object, scene);
        } else if (object.fileExtWithoutDot === "glb" || object.fileExtWithoutDot === "gltf") {
            setIsLoading(true);
            loaderGLTF.load(
                object.downloadUrl,
                (gltf) => {
                    gltf.scene.name = object._id || '';
                    scene.add(gltf.scene);
                    setIsLoading(false);
                }, () => { },
                (e) => {
                    console.error(e)
                    setIsLoading(false)
                    onObjectError(object._id || "", "Error loading content. Please try again or reupload the content")
                },
            );
        } else if (object.fileExtWithoutDot === "png" || object.fileExtWithoutDot === "jpg" || object.fileExtWithoutDot === "jpeg") {
            const texture = new THREE.TextureLoader().load(
                object.downloadUrl,
                () => {
                    // tex and texture are the same in this example, but that might not always be the case

                    // update scale here
                    texture.colorSpace = THREE.SRGBColorSpace
                    let geometry = new THREE.BoxGeometry(texture.image.width / imageScale, texture.image.height / imageScale, 0);
                    let material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
                    let mesh = new THREE.Mesh(geometry, material);
                    const group = new THREE.Group();
                    group.add(mesh)
                    group.name = object._id || '';
                    scene.add(group);
                },
                () => { },
                (e) => {
                    console.error(e)
                    setIsLoading(false)
                    onObjectError(object._id || "", "Error loading content. Please try again or reupload the content")
                },
            );
        }
    }

    const addBuilding3DToMap = () => {
        if (!map) return;

        map.on('style.load', () => {
            // Insert the layer beneath any symbol layer.
            const layers = map.getStyle().layers;
            if (!layers) return;

            const labelLayer = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout && layer.layout['text-field']
            );
            if (!labelLayer) return;
            const labelLayerId = labelLayer.id

            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetMap.
            map.addLayer(
                {
                    'id': '3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#ddd',

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        'fill-extrusion-height': [
                            'interpolate',
                            ['exponential', 5],
                            ['zoom'],
                            15,
                            0,
                            17,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['exponential', 5],
                            ['zoom'],
                            15,
                            0,
                            17,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 0.8
                    }
                    // dynamic opacity change
                },
                labelLayerId
            );
        });
    }

    const addModelsToMap = () => {
        if (!map) {
            return;
        }

        const customLayerType = 'custom' as const;
        const customLayerRemderMode = '3d' as const;
        const loaderGLTF = new GLTFLoader();
        const camera = new THREE.PerspectiveCamera();
        const scene = new THREE.Scene();
        const raycaster = new THREE.Raycaster();
        let mercatorMeterScale: number;
        let originMercator: THREE.Vector3;
        let control: TransformControls;
        let renderer: THREE.WebGLRenderer;

        // configuration of the custom layer for a 3D model per the CustomLayerInterface
        const arContentLayer: mapboxgl.AnyLayer = {
            id: nanoid(),
            type: customLayerType,
            renderingMode: customLayerRemderMode,
            onAdd: function (map: mapboxgl.Map, gl: WebGLRenderingContext) {
                // create two three.js lights to illuminate the model
                const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
                directionalLight.position.set(0, 0.5, -1).normalize();
                scene.add(directionalLight);

                const ambientLight = new THREE.AmbientLight(0xffffff, 3)
                scene.add(ambientLight)

                arContentsRef.current.forEach((object) => { add3dModelToScene(scene, object, loaderGLTF) })

                setMap(map);

                // use the Mapbox GL JS map canvas for three.js
                renderer = new THREE.WebGLRenderer({
                    canvas: map.getCanvas(),
                    context: gl,
                });
                control = new TransformControls(camera, renderer.domElement, true)
                control.reset()
                control.detach()
                control.size = 1
                control.addEventListener('change', (event: any) => {
                    renderer.render(scene, camera)
                    if (!control.dragging) return
                    control.object && handleArContentUpdate(control.object, originMercator, mercatorMeterScale, false);
                });
                control.addEventListener('dragging-changed', (event: any) => {
                    if (event.value) {
                        map.dragPan.disable();
                    } else {
                        control.object && handleArContentUpdate(control.object, originMercator, mercatorMeterScale, true);
                        map.dragPan.enable();
                    }
                });


                map.on('mousemove', e => {
                    const point = e.point;
                    const canvas = map.getCanvas();
                    const [cameraPosition, viewDirection] = getRaycasterParams(point, canvas, camera)
                    control.setMapboxRaycasterParams(cameraPosition, viewDirection);
                })

                if (EnableTransformControl) {
                    scene.add(control)
                }
                map.on('click', e => {
                    // raycasting magic here
                    const point = e.point;
                    const canvas = map.getCanvas();
                    const [cameraPosition, viewDirection] = getRaycasterParams(point, canvas, camera)
                    control.setMapboxRaycasterParams(cameraPosition, viewDirection);

                    raycaster.set(cameraPosition, viewDirection);

                    var intersects = raycaster.intersectObjects(scene.children, true);
                    if (intersects.length) {
                        const filteredIntersects = filterBlacklistedObject(intersects)
                        let objectBuffer: THREE.Object3D | null = filteredIntersects[0]?.object
                        while (true) {
                            if (objectBuffer === null || objectBuffer === undefined) {
                                control.detach();
                                onObjectClick("");
                                break;
                            }
                            if (objectBuffer.type === 'Group') {
                                onObjectClick(objectBuffer.name)
                                break;
                            }
                            objectBuffer = objectBuffer.parent
                        }
                    } else {
                        control.detach();
                        onObjectClick("");
                    }
                });
                renderer.autoClear = false;
            },
            render: function (gl: WebGLRenderingContext, matrix: number[]) {
                const currentLng = +map.getCenter().lng.toFixed(4);
                const currentLat = +map.getCenter().lat.toFixed(4);
                const sceneTransform = getSceneTransform(currentLng, currentLat)
                mercatorMeterScale = sceneTransform.scale
                originMercator = new THREE.Vector3(
                    sceneTransform.translateX,
                    sceneTransform.translateY,
                    sceneTransform.translateZ,
                )

                control.setMode(controlModeRef.current)
                // control attach something
                const attachedModel = selectedContentRef.current !== '' ? scene.getObjectByName(selectedContentRef.current) : undefined
                if (attachedModel !== undefined) {
                    control.attach(attachedModel)
                } else {
                    control.detach()
                }

                if (!control.visible) {
                    control.detach();
                }

                arContentsRef.current.forEach((arContent) => {
                    // add content here instead
                    const object = scene.getObjectByName(arContent._id || '')
                    const objects = scene.getObjectsByProperty("name", arContent._id)
                    if (objects.length > 1) {
                        // add3dModel is async operation. It will sometime not aware of the object added before.
                        objects.forEach((object, index) => {
                            if (index === 0) return
                            scene.remove(object)
                        })
                    }
                    if (!object) {
                        add3dModelToScene(scene, arContent, loaderGLTF)
                    } else if (!(control.object?.name === arContent._id && control.dragging)) {
                        const modelTransform = getModelTransform(arContent, originMercator, mercatorMeterScale)
                        object.position.set(
                            modelTransform.translateX,
                            modelTransform.translateY,
                            modelTransform.translateZ,
                        )
                        object.rotation.set(
                            modelTransform.rotateX,
                            modelTransform.rotateY,
                            modelTransform.rotateZ,
                        )
                        object.scale.set(
                            modelTransform.scaleX,
                            modelTransform.scaleY,
                            modelTransform.scaleZ,
                        )
                    }
                })

                const rotationX = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(1, 0, 0),
                    sceneTransform.rotateX
                );
                const rotationY = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 1, 0),
                    sceneTransform.rotateY
                );
                const rotationZ = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 0, 1),
                    sceneTransform.rotateZ
                );

                const m = new THREE.Matrix4().fromArray(matrix);
                const l = new THREE.Matrix4()
                    .makeTranslation(
                        sceneTransform.translateX,
                        sceneTransform.translateY,
                        sceneTransform.translateZ!
                    )
                    .scale(
                        new THREE.Vector3(
                            sceneTransform.scale,
                            -sceneTransform.scale,
                            sceneTransform.scale
                        )
                    )
                    .multiply(rotationX)
                    .multiply(rotationY)
                    .multiply(rotationZ);

                camera.projectionMatrix = m.multiply(l);

                renderer.resetState();
                renderer.render(scene, camera);
                map.triggerRepaint();
            }
        };

        map.on('style.load', () => {
            map.addLayer(arContentLayer);
        });

    }

    const addPointCloudToMap = () => {
        if (map) {
            // setIsAddModel(true);

            const pointcloudLayers = pointclouds.map((pointcloud, i) => {
                let camera: THREE.Camera;
                let scene: THREE.Scene;
                let renderer: THREE.WebGLRenderer;

                const customLayerType = 'custom' as const;
                const customLayerRemderMode = '3d' as const;
                // configuration of the custom layer for a 3D model per the CustomLayerInterface
                const pointcloudLayer: mapboxgl.AnyLayer = {
                    id: nanoid(),
                    type: customLayerType,
                    renderingMode: customLayerRemderMode,
                    onAdd: function (map: mapboxgl.Map, gl: WebGLRenderingContext) {
                        camera = new THREE.PerspectiveCamera();
                        scene = new THREE.Scene();

                        // Load Point Cloud
                        pointcloudLoader(scene, pointcloud);

                        setMap(map);

                        // use the Mapbox GL JS map canvas for three.js
                        renderer = new THREE.WebGLRenderer({
                            canvas: map.getCanvas(),
                            context: gl,
                            // antialias: true
                        });

                        renderer.autoClear = false;
                    },
                    render: function (gl: WebGLRenderingContext, matrix: number[]) {
                        // TODO: Multi point cloud
                        const modelOrigin: [number, number] = [pointcloud.originCoordinate.displayOriginCoordinates[0], pointcloud.originCoordinate.displayOriginCoordinates[1]];
                        const modelAltitude = pointcloud.originCoordinate.displayOriginCoordinates[2] || 0;
                        const modelRotate = [Math.PI / 2, 0, 0];

                        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                            modelOrigin,
                            modelAltitude
                        );

                        // transformation parameters to position, rotate and scale the 3D model onto the map
                        const modelTransform = {
                            translateX: modelAsMercatorCoordinate.x,
                            translateY: modelAsMercatorCoordinate.y,
                            translateZ: modelAsMercatorCoordinate.z,
                            rotateX: modelRotate[0],
                            rotateY: modelRotate[1],
                            rotateZ: modelRotate[2],
                            /* Since the 3D model is in real world meters, a scale transform needs to be
                            * applied since the CustomLayerInterface expects units in MercatorCoordinates.
                            */
                            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
                        };

                        const rotationX = new THREE.Matrix4().makeRotationAxis(
                            new THREE.Vector3(1, 0, 0),
                            modelTransform.rotateX
                        );
                        const rotationY = new THREE.Matrix4().makeRotationAxis(
                            new THREE.Vector3(0, 1, 0),
                            modelTransform.rotateY
                        );
                        const rotationZ = new THREE.Matrix4().makeRotationAxis(
                            new THREE.Vector3(0, 0, 1),
                            modelTransform.rotateZ
                        );

                        const m = new THREE.Matrix4().fromArray(matrix);
                        const l = new THREE.Matrix4()
                            .makeTranslation(
                                modelTransform.translateX,
                                modelTransform.translateY,
                                modelTransform.translateZ!
                            )
                            .scale(
                                new THREE.Vector3(
                                    modelTransform.scale,
                                    -modelTransform.scale,
                                    modelTransform.scale
                                )
                            )
                            .multiply(rotationX)
                            .multiply(rotationY)
                            .multiply(rotationZ);

                        camera.projectionMatrix = m.multiply(l);

                        renderer.resetState();
                        renderer.render(scene, camera);
                        map.triggerRepaint();
                    }
                };

                return pointcloudLayer;
            });

            map.on('style.load', () => {
                pointcloudLayers.forEach(layer => {
                    if (layer) {
                        map.addLayer(layer);
                    }
                });
            });

            // map.on()
        }
    }

    const addPointCloudPins = () => {
        if (map === undefined || map === null || isPointCloudFetching) return;
        let geoJsonColl: GeoJSON.FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }
        pointClouds.forEach(e => {
            if (!e.publicPointCloudFile) return
            let avgPoint = calculateMidpoint(e.location.coordinates[0])
            let data: GeoJSON.Feature = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [avgPoint[0], avgPoint[1]],
                },
                properties: {
                    id: e._id,
                },
            };
            geoJsonColl.features.push(data);

            const container = document.createElement('div');
            container.setAttribute("pointcloud-id", e._id)
            createRoot(container).render(
                <Marker pointCloudPlace={e} link={`${window.location.pathname}?anchor_type=world_anchor&point_cloud_id=${e._id}`} />
            );

            // Create a Mapbox Marker at our new DOM node
            new mapboxgl.Marker(container, { anchor: "bottom" })
                .setLngLat([avgPoint[0], avgPoint[1]])
                .addTo(map);

        });
    }

    const calculateMidpoint = (coordinates: any) => {
        var totalLat = 0
        var totalLng = 0
        var totalPoints = coordinates.length

        // Calculate the sum of all latitudes and longitudes
        for (var i = 0; i < coordinates.length; i++) {
            totalLat += coordinates[i][0]
            totalLng += coordinates[i][1]
        }

        // Calculate the average latitude and longitude
        var avgLat = totalLat / totalPoints
        var avgLng = totalLng / totalPoints

        // Return the midpoint as an array [latitude, longitude]
        return [avgLat, avgLng]
    }

    // EFFECT ON EVERY RENDER
    // useEffect(() => {
    // });

    // EFFECT ONCE ON LOAD
    useEffect(() => {
        initMap();
    }, []);

    // EFFECT ON MAP CHANGE
    useEffect(() => {
        mapMoveHandler();
        // mapClickHandler();
        addModelsToMap();
        addBuilding3DToMap();
        addPointCloudToMap();
        // initAnchorLayer();
        if (map) {
            map.on('load', function () {
                map.resize();
            });
            map.on('style.load', () => {
                // setConfig was recently introduced in mapboxgl 3.0 types have not yet support it.
                // @ts-ignore
                map.setConfigProperty('basemap', 'lightPreset', 'day');
            })
        }
    }, [map]);

    useEffect(() => {
        addPointCloudPins();
    }, [map, isPointCloudFetching]);

    return (
        <React.Fragment>
            <UsageTip variant={TipVariant.Map} />
            <div ref={mapContainer} className="map-container" />
            <div className='loading' style={{ display: isLoading ? "block" : "none" }} >
                <Spinner type="grow" className="loading-spinner" color="primary" />
                <h6 className='mt-4'>Loading models...</h6>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const WorldAnchorEditor = connect(mapStateToProps)(WorldAnchorEditorComponent);
export { WorldAnchorEditor }
