import React, { useState } from "react"
import {
    Label,
    InputGroup,
    Input,
    Row,
} from "reactstrap";
import { Poi } from "pages/Projects/data";
import { connect } from "react-redux";
import { EditorState } from "store/editor/types";
import { ApplicationState } from "store/data";
import "./poiOption.scss"
import { Box } from "components/Common/Layout/Layout";
import { InputType } from "reactstrap/types/lib/Input"
import { FloorSelector } from "./components/FloorSelector";
import { Dispatch } from "redux";
import { changeSelectedFloor } from "store/actions";

const LabeledInput = (({ title, value, type, onChange, onStateChange, inputComponent }: {
    title: string,
    value: number,
    type: InputType,
    onChange: (value: any) => void,
    onStateChange: (value: any) => void,
    inputComponent: React.ReactNode,
}) => {
    const handleChange = (isBlur: boolean) => (value: any) => {
        if (!isNaN(parseFloat(value))) {
            onChange(value)
            return
        }
        if (isBlur) {
            onChange(0)
        }
        onStateChange(value)
    }

    return <>
        <div className="text-muted">
            <Box display="flex" justifyContent="space-between" alignItem="center" className="mb-2">
                <Label className="mb-0">{title}</Label>
                <Box display="flex">
                    <Input
                        className="labelInput"
                        bsSize="sm"
                        value={value}
                        type={type}
                        onChange={(e) => {
                            handleChange(false)(e.target.value)
                        }}
                        onBlur={(e) => {
                            handleChange(true)(e.target.value)
                        }}
                    />
                </Box>
            </Box>
            {inputComponent}
        </div>
    </>
})

interface OwnProps {
    pois: Poi[]
    onUpdatePoiState: (index: number, obj: Poi) => void
    onUpdatePoi: (index: number, obj: Poi) => void
    onRemovePoi: (obj: Poi) => void
}

interface StateProps {
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

type PoiOptionProps = StateProps & OwnProps & DispatchProps

const PoiOptionComponent = ({
    pois,
    onUpdatePoiState,
    onUpdatePoi,
    onRemovePoi,
    editorState,
    dispatch,
}: PoiOptionProps) => {
    const index = pois.findIndex(poi => poi && poi._id === editorState.selectedObjectId);
    const poi = pois[index];
    const error = editorState.errors.find(error => error.objectid === poi._id)
    const [pendingChange, setPendingChange] = useState({
        position: {
            x: false,
            y: false,
            z: false,
        },
        rotation: {
            x: false,
            y: false,
            z: false,
        },
        scale: {
            x: false,
            y: false,
            z: false,
        },
    })

    const relativePositionLimit = 10
    const minRelativePosition = -relativePositionLimit.toString()
    const maxRelativePosition = relativePositionLimit.toString()

    const handlePositionChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...poi };
        const newPosition = { ...poi.position }
        newPosition[axis] = parseFloat(parseFloat(value).toFixed(2));
        if (newPosition[axis] === updateObj.position[axis]) {
            return
        }

        updateObj.position = newPosition

        if (shouldUpdateServer) {
            onUpdatePoi(index, updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                position: {
                    ...pendingChange.position,
                    [axis]: true,
                }
            })
            onUpdatePoiState(index, updateObj);
        }
    }

    const handleTextChange = (key: keyof Poi) => (value: string) => {
        const updateObj = { ...poi };
        updateObj[key] = value as any
        onUpdatePoi(index, updateObj)
    }

    if (!poi) return null;


    const handleSliderRelease = (key: "position" | "rotation" | "scale", axis: "x" | "y" | "z") => () => {
        if (pendingChange[key][axis]) {
            onUpdatePoi(index, poi)
            setPendingChange({
                ...pendingChange,
                [key]: {
                    ...pendingChange[key],
                    [axis]: false,
                }
            })
        }
    }

    return (
        <div className="PoiOption">
            {/* <AnchorOptions /> */}
            {
                error && <p className="text-danger">
                    {error.message}
                </p>
            }

            <Row className="mb-3">
                <Label className="mb-2">Name</Label>
                <Box display="flex" className="mb-2">
                    <Input
                        className="labelInput"
                        value={poi.displayName}
                        onChange={(e) => {
                            handleTextChange("displayName")(e.target.value)
                        }}
                    />
                </Box>
            </Row>
            {
                editorState.floors.length > 1 && <div className="row mt-2 mb-4" data-testid="floor-option">
                    <h6>Floor</h6>
                    <InputGroup>
                        <FloorSelector
                            floors={editorState.floors}
                            selectedFloorId={poi.floorId}
                            onFloorChange={(floorId?: string) => {
                                if (!floorId) return
                                const updatedPoi = {
                                    ...poi,
                                    floorId,
                                }
                                onUpdatePoi(index, updatedPoi)
                                const newFloor = editorState.floors.find(floor => floor._id === floorId)
                                dispatch(changeSelectedFloor(newFloor))
                            }}
                        />
                    </InputGroup>
                </div>
            }
            {/* Relative Position Options */}
            <React.Fragment>
                <h6>Position</h6>
                <div className="row mb-3" data-testid="relative-position-option-group">
                    <LabeledInput
                        title="X-axis"
                        type="number"
                        value={poi.position.x}
                        onStateChange={handlePositionChange('x')(false)}
                        onChange={handlePositionChange('x')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min={minRelativePosition}
                                max={maxRelativePosition}
                                step={0.5}
                                id="positionX"
                                value={poi.position.x}
                                onChange={e => handlePositionChange('x')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('position', 'x')} />
                        }
                    />
                    <LabeledInput
                        title="Y-axis"
                        type="number"
                        value={poi.position.y}
                        onStateChange={handlePositionChange('y')(false)}
                        onChange={handlePositionChange('y')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min={minRelativePosition}
                                max={maxRelativePosition}
                                step={0.5}
                                id="positionY"
                                value={poi.position.y}
                                onChange={e => handlePositionChange('y')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('position', 'y')} />
                        }
                    />
                    <LabeledInput
                        title="Z-axis"
                        type="number"
                        value={poi.position.z}
                        onStateChange={handlePositionChange('z')(false)}
                        onChange={handlePositionChange('z')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min={minRelativePosition}
                                max={maxRelativePosition}
                                step={0.5}
                                id="positionZ"
                                value={poi.position.z}
                                onChange={e => handlePositionChange('z')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('position', 'z')} />
                        }
                    />
                </div>
            </ React.Fragment>

            <div className="row">
                <Label>Delete Poi</Label>
                <div className="btn-group mb-4">
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        data-testid="delete-content-button"
                        onClick={() => onRemovePoi(poi)}
                    >
                        <i className="mdi mdi-trash-can-outline font-size-16 align-middle me-2"></i>{" "}
                        Delete
                    </button>
                </div>
            </div>
        </div >
    )
}

PoiOptionComponent.displayName = "PoiOptionComponent";

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const PoiOption = connect(mapStateToProps)(PoiOptionComponent);

export { PoiOption, PoiOptionComponent }
