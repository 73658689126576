import React from 'react'
import { useThree } from '@react-three/fiber'
import { TransformControls } from '@react-three/drei'

export enum ControlMode {
    Translate = "translate",
    Rotate = "rotate",
    Scale = "scale",
}

interface ControlProps {
    selectedObjectId: string | undefined
    onArContentChange: (object: THREE.Object3D, shouldUpdateServer: boolean) => void
    controlMode: ControlMode
    onMouseDown?: (e?: THREE.Event) => void;
    onMouseUp?: (e?: THREE.Event) => void;
}

const Controls = ({
    selectedObjectId,
    onArContentChange,
    controlMode,
    onMouseDown = () => {},
    onMouseUp = () => {}
}: ControlProps) => {
    const { scene } = useThree()
    if (!selectedObjectId) return <></>
    const objectInScene = scene.getObjectByName(selectedObjectId)
    if (objectInScene === undefined) return <></>
    return <>
        <TransformControls
            object={objectInScene}
            mode={controlMode}
            onChange={(_e) => {
                onArContentChange(objectInScene, false)
            }}
            onMouseUp={(e) => {
                onArContentChange(objectInScene, true)
                onMouseUp(e)
            }}
            onMouseDown={(e) => {
                onMouseDown(e)
            }}
        />
    </>
}

export {Controls}
