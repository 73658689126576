import { Floor } from "pages/Projects/data";
import { EditorError } from "pages/Projects/types";

export enum SelectedObjectType {
    ArContent = "arContent",
    Poi = "poi",
}

export interface EditorState {
    selectedObjectId: string;
    selectedObjectType: SelectedObjectType;
    selectedFloor?: Floor;
    floors: Floor[];
    errors: EditorError[];
    objectInitialPosition: THREE.Vector3;
    showMapOverlay: boolean
    isMapOverlayAvailable: boolean
}