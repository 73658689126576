import React, { useEffect, useState, ChangeEvent, FocusEvent } from "react"
import {
    Label,
    InputGroup,
    Button,
    Input,
    Row,
    Col,
} from "reactstrap";
import { ARAnchorType, ARContent, ARContentType } from "pages/Projects/data";
import { connect } from "react-redux";
import { EditorState } from "store/editor/types";
import { ApplicationState } from "store/data";
import "./arContentOption.scss"
import { UploadManager } from "../common/UploadManager/UploadManager";
import { Box } from "components/Common/Layout/Layout";
import { InputType } from "reactstrap/types/lib/Input"
import { FloorSelector } from "./components/FloorSelector";
import { Dispatch } from "redux";
import { changeSelectedFloor } from "store/actions";

const LabeledInput = (({ title, value, type, onChange, onStateChange, inputComponent }: {
    title: string,
    value: number,
    type: InputType,
    onChange: (value: any) => void,
    onStateChange: (value: any) => void,
    inputComponent: React.ReactNode,
}) => {
    const handleChange = (isBlur: boolean) => (value: any) => {
        if (!isNaN(parseFloat(value))) {
            onChange(value)
            return
        }
        if (isBlur) {
            onChange(0)
        }
        onStateChange(value)
    }

    return <>
        <div className="text-muted">
            <Box display="flex" justifyContent="space-between" alignItem="center" className="mb-2">
                <Label className="mb-0">{title}</Label>
                <Box display="flex">
                    <Input
                        className="labelInput"
                        bsSize="sm"
                        value={value}
                        type={type}
                        onChange={(e) => {
                            handleChange(false)(e.target.value)
                        }}
                        onBlur={(e) => {
                            handleChange(true)(e.target.value)
                        }}
                    />
                </Box>
            </Box>
            {inputComponent}
        </div>
    </>
})

interface OwnProps {
    arContents: ARContent[]
    onUpdateARContentState: (index: number, obj: ARContent) => void
    onUpdateARContent: (index: number, obj: ARContent) => void
    onRemoveARContent: (obj: ARContent) => void
}

interface StateProps {
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

type ARContentOptionProps = StateProps & OwnProps & DispatchProps

const ARContentOptionComponent = ({
    arContents,
    onUpdateARContentState,
    onUpdateARContent,
    onRemoveARContent,
    editorState,
    dispatch,
}: ARContentOptionProps) => {
    const moveStep = 0.0001;
    const altitudeStep = 0.5;
    const index = arContents.findIndex(arContent => arContent && arContent._id === editorState.selectedObjectId);
    const arContent = arContents[index];
    const error = editorState.errors.find(error => error.objectid === arContent._id)
    const [renderRadiusInMeter, setRenderRadiusInMeter] = useState(arContent?.renderRadiusInMeter);
    const [lockedScale, setLockedScale] = useState(false);
    const [pendingChange, setPendingChange] = useState({
        position: {
            x: false,
            y: false,
            z: false,
        },
        rotation: {
            x: false,
            y: false,
            z: false,
        },
        scale: {
            x: false,
            y: false,
            z: false,
        },
    })

    useEffect(() => {
        if (arContent?.scale.x === arContent?.scale.y && arContent?.scale.x === arContent?.scale.z) {
            setLockedScale(true)
        }
        else {
            setLockedScale(false)
        }
    }, [arContent])

    const handleScaleChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...arContent };
        const newScale = { ...arContent.scale }
        if (lockedScale) {
            newScale.x = parseFloat(parseFloat(value).toFixed(2));
            newScale.y = parseFloat(parseFloat(value).toFixed(2));
            newScale.z = parseFloat(parseFloat(value).toFixed(2));
        } else newScale[axis] = parseFloat(parseFloat(value).toFixed(2));

        if (
            newScale['x'] === updateObj.scale['x']
            && newScale['y'] === updateObj.scale['y']
            && newScale['z'] === updateObj.scale['z']
        ) {
            return
        }

        updateObj.scale = newScale

        if (shouldUpdateServer) {
            onUpdateARContent(index, updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                scale: {
                    ...pendingChange.scale,
                    [axis]: true,
                }
            })
            onUpdateARContentState(index, updateObj);
        }
    }

    const handleRotationChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...arContent };
        const newRotation = { ...arContent.rotation }
        newRotation[axis] = parseFloat(parseFloat(value).toFixed(2)) % 360;
        if (newRotation[axis] === updateObj.rotation[axis]) {
            return
        }

        updateObj.rotation = newRotation

        if (shouldUpdateServer) {
            onUpdateARContent(index, updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                rotation: {
                    ...pendingChange.rotation,
                    [axis]: true,
                }
            })
            onUpdateARContentState(index, updateObj);
        }
    }

    const handlePositionChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...arContent };
        const newPosition = { ...arContent.position }
        newPosition[axis] = parseFloat(parseFloat(value).toFixed(2));
        if (newPosition[axis] === updateObj.position[axis]) {
            return
        }

        updateObj.position = newPosition

        if (shouldUpdateServer) {
            onUpdateARContent(index, updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                position: {
                    ...pendingChange.position,
                    [axis]: true,
                }
            })
            onUpdateARContentState(index, updateObj);
        }
    }

    const toggleLock = () => {
        if (!lockedScale) {
            let updateObj = arContent;
            updateObj.scale.y = updateObj.scale.x;
            updateObj.scale.z = updateObj.scale.x;
            onUpdateARContentState(index, updateObj);
        }
        setLockedScale(!lockedScale)
    }

    const handleUploadSuccess = (addedArContents: ARContent[], shouldRefresh: boolean) => {
        // only handle edit
        const addedArContent = addedArContents[0]
        const arContentIndex = arContents.findIndex(e => e._id === addedArContent._id)
        if (arContentIndex !== -1) {
            onUpdateARContentState(arContentIndex, addedArContent)
        } else {
            return
        }
        if (shouldRefresh) {
            window.location.reload(); // how to remove this??
        }
    }

    if (!arContent) return null;

    const isARDetectionImage = arContent.arAnchorType === ARAnchorType.ImageAnchor && arContent.arContentType === ARContentType.DetectionImage;
    const isFaceParent = arContent.arAnchorType === ARAnchorType.FaceAnchor && arContent.arContentType === ARContentType.FaceParent;
    const isArParentContent = isARDetectionImage || isFaceParent;
    const isParentChildAnchor = arContent.arAnchorType === ARAnchorType.ImageAnchor || arContent.arAnchorType === ARAnchorType.FaceAnchor;
    const isIndoorPointCloud = arContent.arAnchorType === ARAnchorType.WorldAnchor && arContent.isIndoorPointCloud;
    const isReferenceContent = arContent.arContentType === ARContentType.ReferenceImage
    const isRelativePosition = (isParentChildAnchor && !isArParentContent) || isIndoorPointCloud
    const relativePositionLimit = isIndoorPointCloud ? 10 : 10
    const minRelativePosition = -relativePositionLimit.toString()
    const maxRelativePosition = relativePositionLimit.toString()

    const handleSliderRelease = (key: "position" | "rotation" | "scale", axis: "x" | "y" | "z") => () => {
        if (pendingChange[key][axis]) {
            onUpdateARContent(index, arContent)
            setPendingChange({
                ...pendingChange,
                [key]: {
                    ...pendingChange[key],
                    [axis]: false,
                }
            })
        }
    }

    const handleMiscSliderRelease = () => {
        onUpdateARContent(index, arContent)
    }

    return (
        <div className="arContentOption">
            {!(isArParentContent || isReferenceContent) &&
                <>
                    <div className="row mt-2 mb-4" data-testid="content-id-copy-option">
                        <InputGroup className="mb-3">
                            <div className="input-group-prepend">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => { navigator.clipboard.writeText(arContent.arContentId) }}
                                >
                                    Copy ID
                                </button>
                            </div>
                            <Input
                                disabled
                                value={arContent.arContentId}
                                className="form-control"
                            />
                        </InputGroup>
                        <Label>Name</Label>
                        <p className="mb-0" style={{ overflowWrap: 'break-word' }}>{`${arContent.userFilename}.${arContent.fileExtWithoutDot}`}</p>
                    </div>
                </>
            }
            {
                editorState.floors.length > 1 && <div className="row mb-4" data-testid="floor-option">
                    <h6>Floor</h6>
                    <InputGroup>
                        <FloorSelector
                            floors={editorState.floors}
                            selectedFloorId={arContent.floorId}
                            onFloorChange={(floorId?: string) => {
                                if (!floorId) return
                                const updatedArContent = {
                                    ...arContent,
                                    floorId,
                                }
                                onUpdateARContent(index, updatedArContent)
                                const newFloor = editorState.floors.find(floor => floor._id === floorId)
                                dispatch(changeSelectedFloor(newFloor))
                            }}
                        />
                    </InputGroup>
                </div>
            }
            {/* <AnchorOptions /> */}
            {
                error && <p className="text-danger">
                    {error.message}
                </p>
            }
            {/* Relative Position Options */}
            {
                isRelativePosition &&
                <React.Fragment>
                    <h6>Position</h6>
                    <div className="row mb-3" data-testid="relative-position-option-group">
                        <LabeledInput
                            title="X-axis"
                            type="number"
                            value={arContent.position.x}
                            onStateChange={handlePositionChange('x')(false)}
                            onChange={handlePositionChange('x')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min={minRelativePosition}
                                    max={maxRelativePosition}
                                    step={0.5}
                                    id="positionX"
                                    value={arContent.position.x}
                                    onChange={e => handlePositionChange('x')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('position', 'x')} />
                            }
                        />
                        <LabeledInput
                            title="Y-axis"
                            type="number"
                            value={arContent.position.y}
                            onStateChange={handlePositionChange('y')(false)}
                            onChange={handlePositionChange('y')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min={minRelativePosition}
                                    max={maxRelativePosition}
                                    step={0.5}
                                    id="positionY"
                                    value={arContent.position.y}
                                    onChange={e => handlePositionChange('y')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('position', 'y')} />
                            }
                        />
                        <LabeledInput
                            title="Z-axis"
                            type="number"
                            value={arContent.position.z}
                            onStateChange={handlePositionChange('z')(false)}
                            onChange={handlePositionChange('z')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min={minRelativePosition}
                                    max={maxRelativePosition}
                                    step={0.5}
                                    id="positionZ"
                                    value={arContent.position.z}
                                    onChange={e => handlePositionChange('z')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('position', 'z')} />
                            }
                        />
                    </div>
                </ React.Fragment>
            }
            {
                (isRelativePosition || arContent.arAnchorType === ARAnchorType.WorldAnchor) &&
                <React.Fragment>
                    <h6>Rotation</h6>
                    <div className="row mb-3" data-testid="rotation-option-group">
                        <LabeledInput
                            title="X-axis"
                            type="number"
                            value={arContent.rotation.x}
                            onStateChange={handleRotationChange('x')(false)}
                            onChange={handleRotationChange('x')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min="-360" max="360"
                                    step={5}
                                    id="rotationX"
                                    value={arContent.rotation.x}
                                    onChange={e => handleRotationChange('x')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('rotation', 'x')} />
                            }
                        />
                        <LabeledInput
                            title="Y-axis"
                            type="number"
                            value={arContent.rotation.y}
                            onStateChange={handleRotationChange('y')(false)}
                            onChange={handleRotationChange('y')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min="-360" max="360"
                                    step={5}
                                    id="rotationY"
                                    value={arContent.rotation.y}
                                    onChange={e => handleRotationChange('y')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('rotation', 'y')} />
                            }
                        />
                        <LabeledInput
                            title="Z-axis"
                            type="number"
                            value={arContent.rotation.z}
                            onStateChange={handleRotationChange('z')(false)}
                            onChange={handleRotationChange('z')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min="-360" max="360"
                                    step={5}
                                    id="rotationZ"
                                    value={arContent.rotation.z}
                                    onChange={e => handleRotationChange('z')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('rotation', 'z')} />
                            }
                        />
                    </div>
                    <Row >
                        <Col className="lockableTitle">
                            <h6 className="lockTitle">Scale</h6>
                        </Col>
                        <Col className="lockableIcon">
                            <i
                                className={`font-size-16 align-middle lockIcon mdi ${lockedScale ? 'mdi-link-lock' : 'mdi-link'}`}
                                onClick={toggleLock}
                            />
                        </Col>
                    </Row>
                    <div className="row mb-3" data-testid="scale-option-group">
                        <div className="text-muted">
                            <LabeledInput
                                title={lockedScale ? "X, Y, Z" : "X-axis"}
                                type="number"
                                value={arContent.scale.x}
                                onStateChange={handleScaleChange('x')(false)}
                                onChange={handleScaleChange('x')(true)}
                                inputComponent={
                                    <input
                                        type="range"
                                        className="form-range"
                                        min={0.01} max={10}
                                        step={0.01}
                                        id="scaleARContent"
                                        value={arContent.scale.x}
                                        onChange={e => handleScaleChange('x')(false)(e.target.value)}
                                        onMouseUp={handleSliderRelease('scale', 'x')} />
                                }
                            />
                        </div>
                        {
                            lockedScale ? <>
                            </> : <>
                                <LabeledInput
                                    title="Y-axis"
                                    type="number"
                                    value={arContent.scale.y}
                                    onStateChange={handleScaleChange('y')(false)}
                                    onChange={handleScaleChange('y')(true)}
                                    inputComponent={
                                        <input
                                            type="range"
                                            className="form-range"
                                            min={0.01} max={10}
                                            step={0.01}
                                            id="scaleARContent"
                                            value={arContent.scale.y}
                                            onChange={e => handleScaleChange('y')(false)(e.target.value)}
                                            onMouseUp={handleSliderRelease('scale', 'y')} />
                                    }
                                />
                                <LabeledInput
                                    title="Z-axis"
                                    type="number"
                                    value={arContent.scale.z}
                                    onStateChange={handleScaleChange('z')(false)}
                                    onChange={handleScaleChange('z')(true)}
                                    inputComponent={
                                        <input
                                            type="range"
                                            className="form-range"
                                            min={0.01} max={10}
                                            step={0.01}
                                            id="scaleARContent"
                                            value={arContent.scale.z}
                                            onChange={e => handleScaleChange('z')(false)(e.target.value)}
                                            onMouseUp={handleSliderRelease('scale', 'z')} />
                                    }
                                />
                            </>
                        }
                    </div>
                </React.Fragment>
            }

            {/* Render Radius Options */}
            {
                (isArParentContent) &&
                <React.Fragment>
                    <h6>Render Options</h6>
                    <div className="mb-2 text-muted" data-testid="render-radius-option-group">
                        <div className="mb-2 text-muted">
                            <Label>Show AR within <span className="text-danger">{renderRadiusInMeter} m.</span></Label>
                            <p className="card-title-desc mb-0">Set to 100 meter for default rendering</p>
                            <input
                                type="range"
                                className="form-range"
                                min="100" max="2000"
                                step={100}
                                id="renderRadiusInMeter"
                                value={renderRadiusInMeter}
                                onChange={(e) => {
                                    let updateObj = arContent;
                                    setRenderRadiusInMeter(parseFloat(e.target.value));
                                    updateObj.renderRadiusInMeter = parseFloat(e.target.value);
                                    onUpdateARContentState(index, updateObj);
                                }}
                                onMouseUp={handleMiscSliderRelease} />
                        </div>

                    </div>
                    <hr />
                </React.Fragment>
            }

            {/* Render Options */}
            {
                (arContent.arAnchorType === ARAnchorType.WorldAnchor && arContent.arContentType !== ARContentType.ReferenceImage) &&
                <React.Fragment>
                    <h6>Render Options</h6>
                    <div className="mb-2 text-muted" data-testid="render-option-group">
                        <Label>Display AR facing with user camera</Label>
                        <InputGroup>
                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    id="is-front-facing-checkbox"
                                    type="checkbox"
                                    checked={arContent.isFrontFacing}
                                    onChange={e => {
                                        let updateObj = arContent;
                                        updateObj.isFrontFacing = e.target.checked;
                                        onUpdateARContent(index, updateObj);
                                    }} />
                                <label
                                    className="form-check-label text-muted"
                                    htmlFor="is-front-facing-checkbox"
                                >
                                    Always face with user
                                </label>
                            </div>
                        </InputGroup>
                        <div className="mb-2 text-muted">
                            <Label>Show AR within <span className="text-danger">{renderRadiusInMeter} m.</span></Label>
                            <p className="card-title-desc mb-0">Set to 0 meter for default rendering</p>
                            <input
                                type="range"
                                className="form-range"
                                min="0" max="50"
                                step={5}
                                id="renderRadiusInMeter"
                                value={renderRadiusInMeter}
                                onChange={(e) => {
                                    let updateObj = arContent;
                                    setRenderRadiusInMeter(parseFloat(e.target.value));
                                    updateObj.renderRadiusInMeter = parseFloat(e.target.value);
                                    onUpdateARContentState(index, updateObj);
                                }}
                                onMouseUp={handleMiscSliderRelease} />
                        </div>
                    </div>
                    <hr />
                </React.Fragment>
            }

            {/* GPS Coordinate Option */}
            {
                ((arContent.arAnchorType === ARAnchorType.WorldAnchor && !isIndoorPointCloud) || isArParentContent) &&
                <>
                    <h6>Properties</h6>
                    <div className="row mb-3" data-testid="gps-option-group">
                        <div className="mb-3 text-muted">
                            <Label>Longitude [-180,180]</Label>
                            <InputGroup>
                                <span
                                    className="input-group-btn input-group-prepend"
                                    onClick={() => {
                                        let updateObj = arContent;
                                        updateObj.location2D.coordinates[0] -= moveStep;
                                        updateObj.location2D.coordinates[0] = parseFloat(updateObj.location2D.coordinates[0].toFixed(10));
                                        if (-180 <= updateObj.location2D.coordinates[0] && updateObj.location2D.coordinates[0] <= 180) {
                                            onUpdateARContent(index, updateObj);
                                        }
                                    }}
                                >
                                    <Button type="button" color="primary">
                                        <i className="mdi mdi-minus" />
                                    </Button>
                                </span>

                                <input
                                    type="number"
                                    className="form-control ms-1 me-1 rounded"
                                    value={arContent.location2D.coordinates[0].toFixed(6)}
                                    placeholder="number"
                                    min="-180" max="180"
                                    data-testid="longtitude-input"
                                    onChange={(e) => {
                                        let updateObj = arContent;
                                        updateObj.location2D.coordinates[0] = parseFloat(e.target.value);
                                        if (-180 <= updateObj.location2D.coordinates[0] && updateObj.location2D.coordinates[0] <= 180) {
                                            onUpdateARContent(index, updateObj);
                                        }
                                    }} />

                                <span className="input-group-append">
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            let updateObj = arContent;
                                            updateObj.location2D.coordinates[0] += moveStep;
                                            updateObj.location2D.coordinates[0] = parseFloat(updateObj.location2D.coordinates[0].toFixed(10));
                                            if (-180 <= updateObj.location2D.coordinates[0] && updateObj.location2D.coordinates[0] <= 180) {
                                                onUpdateARContent(index, updateObj);
                                            }
                                        }}
                                        color="primary"
                                    >
                                        <i className="mdi mdi-plus" />
                                    </Button>
                                </span>
                            </InputGroup>
                        </div>
                        <div className="mb-3 text-muted">
                            <Label>Latitude [-90,90]</Label>
                            <InputGroup>
                                <span
                                    className="input-group-btn input-group-prepend"
                                    onClick={() => {
                                        let updateObj = arContent;
                                        updateObj.location2D.coordinates[1] -= moveStep;
                                        updateObj.location2D.coordinates[1] = parseFloat(updateObj.location2D.coordinates[1].toFixed(10));
                                        if (-90 <= updateObj.location2D.coordinates[1] && updateObj.location2D.coordinates[1] <= 90) {
                                            onUpdateARContent(index, updateObj);
                                        }
                                    }}
                                >
                                    <Button type="button" color="primary">
                                        <i className="mdi mdi-minus" />
                                    </Button>
                                </span>

                                <input
                                    type="number"
                                    className="form-control ms-1 me-1 rounded"
                                    value={arContent.location2D.coordinates[1].toFixed(6)}
                                    placeholder="number"
                                    min="-90" max="90"
                                    onChange={(e) => {
                                        let updateObj = arContent;
                                        updateObj.location2D.coordinates[1] = parseFloat(e.target.value);
                                        if (-90 <= updateObj.location2D.coordinates[1] && updateObj.location2D.coordinates[1] <= 90) {
                                            onUpdateARContent(index, updateObj);
                                        }
                                    }} />

                                <span className="input-group-append">
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            let updateObj = arContent;
                                            updateObj.location2D.coordinates[1] += moveStep;
                                            updateObj.location2D.coordinates[1] = parseFloat(updateObj.location2D.coordinates[1].toFixed(10));
                                            if (-90 <= updateObj.location2D.coordinates[1] && updateObj.location2D.coordinates[1] <= 90) {
                                                onUpdateARContent(index, updateObj);
                                            }
                                        }}
                                        color="primary"
                                    >
                                        <i className="mdi mdi-plus" />
                                    </Button>
                                </span>
                            </InputGroup>
                        </div>
                        {!isArParentContent &&
                            <div className="mb-3 text-muted">
                                <Label>Altitude ({arContent.altitude.toFixed(4)} Meter)</Label>
                                <InputGroup>
                                    <span
                                        className="input-group-btn input-group-prepend"
                                        onClick={() => {
                                            let updateObj = arContent;
                                            updateObj.altitude -= altitudeStep;
                                            onUpdateARContent(index, updateObj);
                                        }}
                                    >
                                        <Button type="button" color="primary">
                                            <i className="mdi mdi-minus" />
                                        </Button>
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control ms-1 me-1 rounded"
                                        value={arContent.altitude.toFixed(4)}
                                        placeholder="number"
                                        min="0" max="1000"
                                        onChange={(e) => {
                                            let updateObj = arContent;
                                            updateObj.altitude = parseFloat(e.target.value);
                                            if (0 <= updateObj.altitude && updateObj.altitude <= 1000) {
                                                onUpdateARContent(index, updateObj);
                                            }
                                        }} />

                                    <span className="input-group-append">
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                let updateObj = arContent;
                                                updateObj.altitude += altitudeStep;
                                                onUpdateARContent(index, updateObj);
                                            }}
                                            color="primary"
                                        >
                                            <i className="mdi mdi-plus" />
                                        </Button>
                                    </span>
                                </InputGroup>
                            </div>}
                    </div>
                </>
            }
            {
                arContent.arContentType === ARContentType.Video && arContent.previewUrl &&
                <div className="row mb-3" data-testid="video-preview">
                    {/* <Label>Upload for platforms</Label> */}
                    <p className="card-title-desc mb-2">Click to preview</p>
                    <div className="btn-group">
                        <button
                            type="button"
                            className={`btn btn-outline-${arContent.previewUrl ? 'success' : 'secondary'}`}
                            onClick={() => {
                                window.open(arContent.previewUrl, "_blank", "noreferrer");
                            }}
                        >
                            <i className="mdi mdi-play font-size-24"></i>
                        </button>
                    </div>
                </div>
            }
            {
                arContent.arContentType === ARContentType.Model3d &&
                <div className="row mb-3">
                    <Label>Upload for platforms</Label>
                    <p className="card-title-desc mb-2">Click to preview <code>.usdz</code> for iOS</p>
                    <div className="btn-group">
                        <button
                            type="button"
                            className={`btn btn-outline-${arContent.storeUrlIos ? 'success' : 'secondary'}`}
                            onClick={() => {
                                window.open("https://usd-viewer-xoeyqjvd6q-as.a.run.app/?file=" + arContent.iosDownloadUrl, "_blank", "noreferrer");
                            }}
                        >
                            <i className="mdi mdi-apple-ios font-size-24"></i> <i className="mdi mdi-play font-size-24"></i>
                        </button>
                        <button
                            disabled
                            type="button"
                            className="btn btn-outline-success">
                            <i className="mdi mdi-android font-size-24"></i>
                        </button>
                    </div>
                    {
                        arContent.isPublicStore && <p className="card-title-desc mb-2">Edit upload is not allowed for starter asset</p>
                    }
                    <Box>
                        <UploadManager
                            onUploadSuccess={handleUploadSuccess}
                            arContent={arContent}
                            variant="edit"
                        />
                    </Box>
                </div>
            }
            <div className="row">
                <Label>Delete Content</Label>
                <div className="btn-group">
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        data-testid="delete-content-button"
                        onClick={() => onRemoveARContent(arContent)}
                    >
                        <i className="mdi mdi-trash-can-outline font-size-16 align-middle me-2"></i>{" "}
                        Delete
                    </button>
                </div>
            </div>
        </div >
    )
}

ARContentOptionComponent.displayName = "ARContentOptionComponent";

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const ARContentOption = connect(mapStateToProps)(ARContentOptionComponent);

export { ARContentOption, ARContentOptionComponent }
