import * as THREE from 'three'
import { useLoader } from '@react-three/fiber'
import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader'
import { ARContent, ARContentType, PointCloudPlace, Vector3 } from "../../../data"
import { imageScale } from 'constants/const'
import { Clone, useGLTF } from '@react-three/drei'
import { useMemo } from 'react'
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import videoCamera from "assets/images/video-camera.png"

interface ObjectTransform {
    position: Vector3
    rotation: Vector3
    scale: Vector3
}

const getObjectTransform = (arContent: ARContent, scale: number, setToOrigin = false): ObjectTransform => {
    const object: ObjectTransform = {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: scale, y: scale, z: scale },
    }
    if (setToOrigin) return object
    if (arContent.arContentType === ARContentType.FaceParent) {
        object.scale = { x: scale, y: scale, z: scale };
        object.rotation = { x: 0, y: 0, z: 0 };
        object.position = { x: 0, y: 0.02, z: -0.093 };
    } else if (arContent.arContentType === ARContentType.DetectionImage) {
        object.rotation.x = Math.PI / 2;
        object.rotation.z = Math.PI;
    } else {
        object.scale = {
            x: arContent.scale.x * scale,
            y: arContent.scale.y * scale,
            z: arContent.scale.z * scale,
        };
        object.position = {
            x: arContent.position.x,
            y: arContent.position.y,
            z: arContent.position.z,
        };
        object.rotation = {
            x: arContent.rotation.x * (Math.PI / 180),
            y: arContent.rotation.y * (Math.PI / 180),
            z: arContent.rotation.z * (Math.PI / 180),
        };
    }
    return object
}

const ArContent3DModel = (arContent: ARContent, onObjectClick: (arContentId: string) => void, scale: number, setToOrigin = false) => {
    const results = useGLTF(arContent.downloadUrl)
    const object = results.scene;
    const clone = useMemo(() => SkeletonUtils.clone(object), [object])
    if (arContent.position === undefined) {
        return <></>
    }
    const objectTransformation = getObjectTransform(arContent, scale, setToOrigin)
    return (
        <group
            position={[
                objectTransformation.position.x,
                objectTransformation.position.y,
                objectTransformation.position.z,
            ]}
            rotation={[
                objectTransformation.rotation.x,
                objectTransformation.rotation.y,
                objectTransformation.rotation.z,
            ]}
            scale={[
                objectTransformation.scale.x,
                objectTransformation.scale.y,
                objectTransformation.scale.z,
            ]}
            key={arContent._id}
            onClick={(e: any) => {
                e.stopPropagation()
                onObjectClick(arContent._id || '')
            }}
            name={arContent.arContentType !== ARContentType.FaceParent ? arContent._id : ''}
        >
            <primitive
                object={clone}
            />
        </group>
    )
}

const ArContentImage = (arContent: ARContent, onObjectClick: (arContentId: string) => void, scale: number, setToOrigin = false) => {
    const objectTransformation = getObjectTransform(arContent, scale, setToOrigin)
    const texture = useLoader(THREE.TextureLoader, arContent.downloadUrl)
    if (arContent.position === undefined) {
        return <></>
    }
    texture.colorSpace = THREE.SRGBColorSpace
    const positionVector = new THREE.Vector3(
        objectTransformation.position.x,
        objectTransformation.position.y,
        objectTransformation.position.z,
    )
    const rotationVector = new THREE.Euler(
        objectTransformation.rotation.x,
        objectTransformation.rotation.y,
        objectTransformation.rotation.z,
    )
    const scaleVector = new THREE.Vector3(
        objectTransformation.scale.x,
        objectTransformation.scale.y,
        objectTransformation.scale.z,
    )
    return (
        <mesh
            key={arContent._id}
            position={positionVector}
            rotation={rotationVector}
            scale={scaleVector}
            name={arContent.arContentType !== ARContentType.DetectionImage ? arContent._id : ''}
            onClick={(e: any) => {
                e.stopPropagation()
                onObjectClick(arContent._id || '')
            }}
        >
            <boxGeometry args={[texture.image.width / imageScale, texture.image.height / imageScale, 0]} />
            <meshBasicMaterial args={[{ map: texture }]} />
        </mesh>
    )
}

const ArContentVideo = (arContent: ARContent, onObjectClick: (arContentId: string) => void, scale: number) => {
    const objectTransformation = getObjectTransform(arContent, scale)
    const texture = useLoader(THREE.TextureLoader, arContent.downloadUrl)
    const videoTexture = useLoader(THREE.TextureLoader, videoCamera)
    if (arContent.position === undefined) {
        return <></>
    }
    texture.colorSpace = THREE.SRGBColorSpace
    const positionVector = new THREE.Vector3(
        objectTransformation.position.x,
        objectTransformation.position.y,
        objectTransformation.position.z,
    )
    const rotationVector = new THREE.Euler(
        objectTransformation.rotation.x,
        objectTransformation.rotation.y,
        objectTransformation.rotation.z,
    )
    const scaleVector = new THREE.Vector3(
        objectTransformation.scale.x,
        objectTransformation.scale.y,
        objectTransformation.scale.z,
    )
    return (
        <group
            key={arContent._id}
            position={positionVector}
            rotation={rotationVector}
            scale={scaleVector}
            name={arContent.arContentType !== ARContentType.DetectionImage ? arContent._id : ''}
            onClick={(e: any) => {
                e.stopPropagation()
                onObjectClick(arContent._id || '')
            }}
        >
            <mesh>
                <boxGeometry args={[texture.image.width / imageScale, texture.image.height / imageScale, 0]} />
                <meshBasicMaterial args={[{ map: texture }]} />
            </mesh>
            <sprite
                scale={0.1}
            >
                <spriteMaterial attach="material" map={videoTexture!} depthTest={false} depthWrite={false} />
            </sprite>
        </group>
    )
}

interface ArContentModelProps {
    arContent: ARContent
    onObjectClick: (arContentId: string) => void
    onError: (arContentId: string, message: string) => void
    scale: number
    setToOrigin?: boolean
}

const ArContentModel = ({ arContent, onObjectClick, onError, scale, setToOrigin = false }: ArContentModelProps) => {
    if (arContent.arContentType === ARContentType.Video) {
        return ArContentVideo(arContent, onObjectClick, scale)
    }
    if (arContent.arContentType === ARContentType.Image || arContent.arContentType === ARContentType.DetectionImage || arContent.arContentType === ARContentType.ReferenceImage) {
        return ArContentImage(arContent, onObjectClick, scale, setToOrigin)
    }
    return ArContent3DModel(arContent, onObjectClick, scale, setToOrigin)

    // ##### 
    // Error Handling to be reintroduced due to internal library issue
    // https://github.com/pmndrs/react-three-fiber/issues/3181
    // #####
    // try {
    //     if (arContent.arContentType === ARContentType.Image || arContent.arContentType === ARContentType.DetectionImage || arContent.arContentType === ARContentType.ReferenceImage) {
    //         return ArContentImage(arContent, onObjectClick, scale, setToOrigin)
    //     }
    //     return ArContent3DModel(arContent, onObjectClick, scale, type, setToOrigin)
    // } catch (e) {
    //     debugger
    //     if (e instanceof Promise) return
    //     console.error(e)
    //     console.log("error catched")
    //     onError(arContent._id || "", "Error loading content. Please try again or reupload the content")
    //     return <></>
    // }
}

interface MapModelProps {
    place: PointCloudPlace
    mapImage: string
    scale: number
    positionY: number
}

const MapModel = ({ place, mapImage, scale, positionY }: MapModelProps) => {
    const texture = useLoader(THREE.TextureLoader, mapImage)
    const textureBottom = texture.clone()
    textureBottom.wrapS = THREE.RepeatWrapping
    textureBottom.repeat.x = -1
    texture.colorSpace = THREE.SRGBColorSpace
    const positionVector = new THREE.Vector3(0, positionY, 0)
    const placeOrigin = place.originCoordinate
    const rotation = new THREE.Quaternion(
        placeOrigin.displayRotation.x,
        placeOrigin.displayRotation.y,
        placeOrigin.displayRotation.z,
        placeOrigin.displayRotation.w,
    )
    const defaultRotation = new THREE.Quaternion(
        0.5, -0.5, 0.5, 0.5
    )
    rotation.multiply(defaultRotation)
    const scaleVector = new THREE.Vector3(scale, scale, scale)
    const topMaterial = new THREE.MeshStandardMaterial({map: texture, transparent: true, opacity: 0.9, alphaTest:0.9})
    const bottomMaterial = new THREE.MeshStandardMaterial({map: texture, transparent: true, opacity: 0.3, alphaTest:0.3})
    return (
        <mesh
            key={place.name}
            position={positionVector}
            quaternion={rotation}
            scale={scaleVector}
            name={place._id}
        >
            <boxGeometry args={[texture.image.width, texture.image.height, 0]} />
            <meshStandardMaterial attach="material-5" args={[{ map: texture }]} transparent={true} opacity={0.9} alphaTest={0.9}/>
            <meshStandardMaterial attach="material-4" args={[{ map: textureBottom }]} transparent={true} opacity={0.2} alphaTest={0.2}/>
        </mesh>
    )
}

export { ArContentModel, getObjectTransform, MapModel }
