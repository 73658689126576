import { ARAnchorType, ARContent, ARContentType, PreviewLocation } from "../data"


export const filterARAnchorType = (arContents: ARContent[], anchorType: ARAnchorType) => {
    return arContents.filter(e => e.arAnchorType === anchorType);
}

export const filterARContentType = (arContents: ARContent[], contentType: ARContentType) => {
    return arContents.filter(e => e.arContentType === contentType);
}

export const filterARContentAndARAnchorType = (arContents: ARContent[], anchorType: ARAnchorType, contentType: ARContentType) => {
    return arContents.filter(e => (e.arAnchorType === anchorType) && (e.arContentType === contentType));
}

export const getContentFromARContentId = (arContents: ARContent[], id: string) => {
    let result = arContents.filter(e => (e.arContentId === id));
    if (result[0]) {
        return result[0];
    }
    return null;
}

export const sortARContents = (arContents: ARContent[]) => {
    return arContents.sort((x, y) => {
        return x.timestamp - y.timestamp;
    });
}

export const getARContentChildsFromParentId = (arContents: ARContent[], parentId: string) => {
    return arContents.filter(content => (content.parentId) && (content.parentId === parentId))
}

export const getDefaultPreviewLocation = (previewLocations: PreviewLocation[]) => {
    let result = previewLocations.filter(e => e.isDefault);
    if (result[0]) return result[0];

    return null;
}