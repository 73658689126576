import React, { useState } from "react"
import SimpleBar from "simplebar-react";
import {
    Button,
    Row,
    Col,
} from "reactstrap";
import { ARContent, Poi } from "pages/Projects/data";
import { connect } from "react-redux";
import { EditorState, SelectedObjectType } from "store/editor/types";
import { ApplicationState } from "store/data";
import { ARContentOption } from "./ARContentOption";
import { Box } from "components/Common/Layout/Layout";
import { ARContentEffect } from "./ARContentEffect";
import { PoiOption } from "./PoiOption";

enum PageEnum {
    object,
    effect
}

const pageList = [
    {
        text: "Object",
        icon: "bi bi-bounding-box-circles",
    },
    {
        text: "Effect",
        icon: "bi bi-bezier2",
    },
]

interface OwnProps {
    arContents: ARContent[]
    pois: Poi[]
    onUpdateARContentState: (index: number, obj: ARContent) => void
    onUpdateARContent: (index: number, obj: ARContent) => void
    onRemoveARContent: (obj: ARContent) => void
    onUpdatePoiState: (index: number, obj: Poi) => void
    onUpdatePoi: (index: number, obj: Poi) => void
    onRemovePoi: (obj: Poi) => void
}

interface StateProps {
    editorState: EditorState
}

type ARContentPropertiesProps = StateProps & OwnProps

const ARContentPropertiesComponent = ({
    arContents,
    pois,
    onUpdateARContentState,
    onUpdateARContent,
    onRemoveARContent,
    onUpdatePoiState,
    onUpdatePoi,
    onRemovePoi,
    editorState,
}: ARContentPropertiesProps) => {
    const [page, setPage] = useState<PageEnum>(PageEnum.object)

    const pageButton = (
        text: string,
        icon: string,
        selected: boolean,
        onClick: () => void,
    ) => (
        <Col key={text} lg={6}>
            <Box fullWidth>
                <Button color="white" block onClick={onClick} className="border border-0">
                    <Box display="flex" justifyContent="center" alignItem="center">
                        {iconBox(<i className={icon} />)}<span className={`ms-1 ${selected ? "" : "opacity-50"}`}>{text}</span>
                    </Box>
                </Button>
                <hr className={`mt-0 mb-2 ${selected ? "border-primary border-2 border-opacity-75 opacity-100" : ""} `} />
            </Box>
        </Col>
    )

    const iconBox = (icon: React.ReactNode) => (
        <Box className="py-1 px-2 rounded text-primary bg-primary bg-opacity-10 fs-6" style={{ width: "24px", height: "24px" }}>
            {icon}
        </Box>
    )

    return (
        <Box background="white" className="h-100 ps-3 py-3">
            {editorState.selectedObjectId !== "" ?
                <SimpleBar style={{ maxHeight: "100%" }} className="pe-3">
                    {
                        editorState.selectedObjectType === SelectedObjectType.ArContent ?
                            <>
                                <Row className="g-0">
                                    {pageList.map((item, idx) => pageButton(
                                        item.text,
                                        item.icon,
                                        idx === page,
                                        () => { setPage(idx) }
                                    ))}
                                </Row>
                                {
                                    page === PageEnum.object
                                        ? <ARContentOption
                                            arContents={arContents}
                                            onUpdateARContent={onUpdateARContent}
                                            onUpdateARContentState={onUpdateARContentState}
                                            onRemoveARContent={onRemoveARContent}
                                        />
                                        : <ARContentEffect
                                            arContents={arContents}
                                            onUpdateARContent={onUpdateARContent}
                                            onUpdateARContentState={onUpdateARContentState}
                                            onRemoveARContent={onRemoveARContent}
                                        />
                                }
                            </> : <PoiOption
                                pois={pois}
                                onUpdatePoi={onUpdatePoi}
                                onUpdatePoiState={onUpdatePoiState}
                                onRemovePoi={onRemovePoi}
                            />
                    }

                </SimpleBar> : <p className="text-center">
                    Select Object to view its properties
                </p>
            }
        </Box>
    )
}

ARContentPropertiesComponent.displayName = "ARContentPropertiesComponent";

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const ARContentProperties = connect(mapStateToProps)(ARContentPropertiesComponent);

export { ARContentProperties, ARContentPropertiesComponent }
